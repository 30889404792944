<template>
  <div class="d-flex justify-center align-center">
    <div class="pb-10">
      <v-card flat rounded class="glass overlay pa-10" width="420">
        <header class="text-center d-flex align-center flex-column">
          <v-img src="../assets/XUMED.png" contain height="130px" width="150px"></v-img>
        </header>
        <v-form v-if="!passwordChanged" @submit.prevent="changePassword()">
          <h3 class="font-weight-bold my-5 text-center">
            Definir nueva contraseña
          </h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newPassword"
                  :error="!validNewPassword && this.newPassword !== ''"
                  label="Contraseña nueva"
                  prepend-icon="mdi-lock"
                  :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPass ? 'text' : 'password'"
                  @click:append="showNewPass = !showNewPass"
                  :hint="
                    validNewPassword
                      ? ''
                      : 'Al menos: 8 caráteres | 1 número | 1 mayúscula | 1 minúscula'
                  "
                  :persistent-hint="
                    !validNewPassword && this.newPassword !== ''
                  "
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newPasswordRepited"
                  :error="!confirmedPassword && this.newPasswordRepited !== ''"
                  label="Confirmar contraseña nueva"
                  prepend-icon="mdi-lock-reset"
                  :append-icon="showNewPassRepited ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPassRepited ? 'text' : 'password'"
                  @click:append="showNewPassRepited = !showNewPassRepited"
                  :hint="confirmedPassword ? '' : 'Contraseñas no coinciden'"
                  :persistent-hint="
                    !confirmedPassword && this.newPasswordRepited !== ''
                  "
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="text--right">
            <v-btn
              small
              depressed
              block
              color="primary"
              class="pa-5"
              type="submit"
              :disabled="!validFields"
              :loading="loading"
            >
              <v-icon class="mr-2">
                {{ validFields ? "mdi-lock-check" : "mdi-lock-remove" }}
              </v-icon>
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
        <div v-else class="d-flex flex-column align-center">
          <v-alert class="my-5" dense border="left" type="success">
            Contraseña cambiada de forma correcta.
          </v-alert>
          <v-btn class="mt-3" color="primary" depressed small to="/login">Iniciar sesión</v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { passwordValidation } from "../helpers/common";

export default {
  name: "ResetPassword",
  data() {
    return {
      newPassword: "",
      newPasswordRepited: "",
      loading: false,
      validation: true,
      showNewPass: false,
      showNewPassRepited: false,
      passwordChanged: false,
    };
  },
  computed: {
    validNewPassword() {
      return passwordValidation(this.newPassword);
    },
    confirmedPassword() {
      return this.newPassword === this.newPasswordRepited;
    },
    validFields() {
      return (
        this.validNewPassword &&
        this.confirmedPassword &&
        this.$route?.params?.token
      );
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    async changePassword() {
      try {
        this.loading = true;
        const form = {
          password: this.newPassword,
          token: this.$route?.params?.token,
        };
        await axios.post("/api/reset-password", form);
        this.newPassword = "";
        this.newPasswordRepited = "";
        this.passwordChanged = true;
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.glass {
  background: rgba(253, 253, 253, 0.68);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  width: 420px;
}

@media only screen and (max-width: 768px) {
  .glass {
    width: auto;
  }
}
</style>
